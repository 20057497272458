import React, {useEffect, useState} from 'react';
import './App.css';

interface JokeObj {
    id: string,
    joke: string,
}

interface JokesResponse {
    current_page: number,
    limit: number,
    next_page: number,
    previous_page: number,
    results: JokeObj[],
    search_term: string,
    status: number,
    total_jokes: number,
    total_pages: number,
}

async function getJokes(pageNumber: number): Promise<string[]> {
    const res = await fetch(`https://icanhazdadjoke.com/search?page=${pageNumber}`, {
        headers: {
            'Accept': 'application/json',
        },
    });
    if (res.ok) {
        const jokes: JokesResponse = await res.json();
        return jokes.results.map((jokeObj: JokeObj) => jokeObj.joke);
    } else {
        throw new Error(`Error getting joke; server responded with code ${res.status}`);
    }
}

async function getJokePageCount(): Promise<number> {
    const res = await fetch('https://icanhazdadjoke.com/search', {
        headers: {
            'Accept': 'application/json',
        },
    });
    if (res.ok) {
        const jokes: JokesResponse = await res.json();
        return jokes.total_pages;
    } else {
        throw new Error(`Error getting joke page count; server responded with code ${res.status}`);
    }
}

function App() {
    const [cursor, setCursor] = useState<number>(1);
    const [jokes, setJokes] = useState<string[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);

    const updateJokes = async (pageNumber: number) => {
        setJokes(await getJokes(pageNumber));
        setPageCount(await getJokePageCount());
    }

    useEffect(() => {
        updateJokes(cursor)
            .then(() => console.log('Updated jokes & page count'))
            .catch((e) => console.error('Error updating jokes', e?.message ?? e));
    }, [cursor]);

    return (
        <div className="container">
            <h1>Dad Jokes!</h1>
            <h2>Page {cursor} of {pageCount}</h2>
            <div className="actions">
                <button id="previous" onClick={() => setCursor(cursor - 1)} disabled={cursor <= 1}>Previous</button>
                <button id="next" onClick={() => setCursor(cursor + 1)} disabled={cursor >= pageCount}>Next</button>
            </div>
            <ul style={{ width: '100%' }}>
                {jokes.length > 0 ? jokes.map((joke) => (
                    <li>{joke}</li>
                )) : [...Array(20)].map(() => (
                    <li>
                        <div className="skeleton" />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default App;
